import network from '@common/network/network';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

export default {
  searchAllRecordsInBackgroundAction(context, filters) {
    const limit = 10;
    const offset = limit * (filters.page - 1);
    filters.limit = limit;
    filters.offset = offset;

    const params = pickBy(filters, identity);

    return new Promise((resolve, reject) => {
      network.source.searchMentions(params).then(resolve).catch(reject);
    });
  },
  searchAllRecordsAction(context, payload) {
    context.commit('setSearchAllRecordsLoadingState', true);
    let {page, ...filters} = payload;
    page = page || 1;
    const limit = 10;
    const offset = limit * (page - 1);
    let params = {
      limit,
      offset,
      ...filters,
    };
    if (params.is_flexible) {
      params.is_birth_location_optional = true;
      params.is_death_location_optional = true;
      params.is_birth_year_optional = true;
      params.is_death_year_optional = true;
    }
    params = pickBy(params, value => !!value);

    return new Promise((resolve, reject) => {
      network.source
        .searchMentions(params)
        .then(response => {
          context.commit('setSearchAllRecordsListState', response.objects);
          context.commit('setSearchAllRecordsMetaState', response.meta);
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setSearchAllRecordsLoadingState', false);
        });
    });
  },
  searchAllRecordsSilentAction(context, filters) {
    return network.source.searchMentions(filters);
  },
  fetchAllRecordsOptionsAction(context) {
    context.commit('setSearchAllRecordsOptionsLoadingState', true);
    return new Promise((resolve, reject) => {
      network.source.searchMentionsOptions().then(response => {
        context.commit('setSearchAllRecordsOptionsState', response);
        resolve(response);
      });
    }).finally(() => {
      context.commit('setSearchAllRecordsOptionsLoadingState', false);
    });
  },
};
